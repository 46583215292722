/* Customize your main colors in :root variables */
:root {
  --main-background-color: #000000;
  --card-background-color: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 55%, rgba(15,15,15,1) 100%);
  --countdown-background-color: #000000;
  --main-text-color:#F7F6F4;
  --title-text-color:#ffffff;
}

body{
  background-color:var(--main-background-color);
  /* background: url("img/background.png"); */
  background-size: cover;
  font-size: 1.1em;
}

h2, p{
  font-weight: 300;
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



