body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img{
  width:200px;
  align-self: center;
}

.logoContainer{
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: -50px;
  margin-bottom: -70px;
}

h1{
  font-weight: 400;
  letter-spacing: 0.15em;
}

h2{
  color: rgb(200, 200 , 200) !important;
  margin-top: -20px;

}

.wallet-adapter-button:hover{ 
  background: linear-gradient(0deg, rgb(22, 22, 22) 0%,  rgb(24, 24, 24) 100%) !important;
}